import { isExcalidrawPlusSignedUser } from "../app_constants";

export const ExcalidrawPlusAppLink = () => {
  if (!isExcalidrawPlusSignedUser) {
    return null;
  }
  return (
    <a
      href={`${import.meta.env.VITE_APP_PLUS_APP}`}
      target="_blank"
      rel="noreferrer"
      className="plus-button"
    >
      Return to Yostudent
    </a>
  );
};
